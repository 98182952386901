* {

  font-family: Inter, ui-sans-serif, system-ui, sans-serif;
  box-sizing: border-box;
  margin: 0;


}


.slanted-section {
  position: relative;
  overflow: hidden;
}

